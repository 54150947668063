import React from "react";
import "./style.css";
import LabelSpan from "../LabelSpan";
export default function TextInput({
  label,
  className,
  placeholder,
  type,
  value,
  onChange,
  disabled
}) {
  const inputLabel = label ? <LabelSpan>{label}</LabelSpan> : null;

  return (
    <label>
      {inputLabel}
      <input
        type="text"
        className={`${className}`}
        placeholder={`${placeholder}`}
        type={`${type}`}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </label>
  );
}
