import React, { useState, useEffect, useContext } from "react";
import PageLayout from "components/layouts/PageLayout";
import Notification from "lib/api/Notification";
import {
  Button,
  TextInput,
  TextArea,
  Checkbox,
  CheckboxGroup
} from "components/common";
export default function NotificationForm(props) {
  const [notification, setNotification] = useState({
    uuid: "",
    title: "",
    target_uuid: "",
    source_uuid: "",
    message: "",
    start_at: "",
    end_at:"",
    read:false
  });

  async function load(uuid) {
    try{
      const response = await Notification.load(uuid);
      await setNotification(response.data);
    }catch(exception){
      console.log(exception);
    }
  }
  async function remove() {
    await Notification.delete(notification.uuid);
    props.history.push(`/notifications`);
  }
  useEffect(() => {
      if(props.match.params.uuid){
        load(props.match.params.uuid);
      }
  }, []);

  const deleteBtn = props.match.params.uuid ? (
    <Button className="button button--danger" onClick={remove}>
      Delete
    </Button>
  ) : (
    ""
  );
  return (
    <PageLayout>
      <h1>Notification Details</h1>
      <div className="hr hr--darkest"></div>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <TextInput
            className="m-bot-0"
            label="Title"
            placeholder="Enter Title"
            type="text"
            value={notification.title}
            disabled={true}
            onChange={e => setNotification({...notification, title: e.target.value})}
          />
        </div>
        <div className="col-xs-12 col-sm-6">
          <TextInput
            className="m-bot-0"
            label="UUID"
            placeholder="Notification UUID"
            type="text"
            value={notification.uuid}
            disabled={true}
            onChange={e => setNotification({...notification, uuid: e.target.value})}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <TextInput
            className="m-bot-0"
            label="Target"
            placeholder="Enter Target UUID"
            type="text"
            disabled={true}
            value={notification.target_uuid}
            onChange={e => setNotification({...notification, target_uuid: e.target.value})}
          />
        </div>
        <div className="col-xs-12 col-sm-6">
          <TextInput
            className="m-bot-0"
            label="Source"
            placeholder="Enter Sourfce UUID"
            type="text"
            value={notification.source_uuid}
            disabled={true}
            onChange={e => setNotification({...notification, source_uuid: e.target.value})}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <TextArea
            label="Message"
            placeholder="Enter Message"
            value={notification.message}
            disabled={true}
            onChange={e => setNotification({...notification, message: e.target.value})}
          />
        </div>
      </div>
      <div className="row">
      <div className="col-xs-12 col-sm-6">
        <label>
          <span className="label__span">Start</span>
          <input type="datetime-local"
            value={notification.start_at || ""}
            onChange={e => setNotification({...notification, start_at: e.target.value})}
            disabled={true}
          />
        </label>
        </div>
        <div className="col-xs-12 col-sm-6">
        <label>
          <span className="label__span">End</span>
          <input type="datetime-local"
            value={notification.end_at || ""}
            onChange={e => setNotification({...notification, end_at: e.target.value})}
            disabled={true}
          />
        </label>
        </div>
        </div>
        <div className="row">
        <div className="col-xs-12 col-sm-6">
          <CheckboxGroup className="checkbox-group--col">
            <Checkbox
              title="Read"
              checked={notification.read}
              disabled={true}
              onChange={e => setNotification({...notification, read: e.target.value})}
            />
          </CheckboxGroup>
        </div>
        </div>
      <div className="hr hr--darkest m-top-10 m-bot-20"></div>
      <div className="row">
        <div className="col-xs-6">
          <div className="button-group">
            {/* <Button className="button" onClick={save}>
              Save
            </Button> */}
            <a className="button button--plain" href="#" onClick={props.history.goBack}>
              Cancel
            </a>
          </div>
        </div>
        <div className="col-xs-6 end-xs">
          <div className="button-group">
            {deleteBtn}
          </div>
        </div>
      </div>
    </PageLayout>
  );
}
