import React from "react";
import logo from "./logo.svg";
import "./style.css";
import AppProfileDropDown from "components/common/AppProfileDropDown";

export default function ButtonGroup(props) {
  return (
    <div className="app-header">
      <div className="app-header__menu">&#9776;</div>
      <div className="app-header__logo">
        <img src={logo} alt="logo" />
      </div>
      <AppProfileDropDown />
    </div>
  );
}
