import React from "react";
import "./style.css";
export default function Checkbox({
  title,
  checked,
  onChange,
  disabled,
  className
}) {
  const checkboxTitle = title ? <span>{title}</span> : null;

  return (
    <label className={`checkbox ${className}`}>
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      {checkboxTitle}
    </label>
  );
}
