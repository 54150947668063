import React, { useReducer, useEffect } from "react";
const intialState = {
  uuid: false,
  name: "System",
  reference_id: "SYSTEM",
  client: {
    name: "ComputerEase",
    reference_id: "CE"
  }
};
let reducer = (info, newInfo) => {
  if (newInfo === null) {
    sessionStorage.removeItem("company");
    return intialState;
  }
  return { ...info, ...newInfo };
};
const initialCompanies = [];
export const CompanyContext = React.createContext();
const sessionState = JSON.parse(sessionStorage.getItem("company"));
export function CompanyProvider(props) {
  const [company, setCompany] = useReducer(
    reducer,
    sessionState || intialState
  );
  const [companies, setCompanies] = useReducer(reducer, initialCompanies);
  useEffect(() => {
    sessionStorage.setItem("company", JSON.stringify(company));
  }, [company]);
  return (
    <CompanyContext.Provider
      value={{ company, setCompany, companies, setCompanies }}
    >
      {props.children}
    </CompanyContext.Provider>
  );
}
