import React, { useState, useEffect } from "react";
import {
  Button,
  TextInput,
} from "components/common";
import { Link } from "react-router-dom";
import PageLayout from "components/layouts/PageLayout";
import Company from "lib/api/Company";
import UserList from "components/user/UserList";
export default function CompanyForm(props) {
  const [uuid, setUuid] = useState("");
  const [name, setName] = useState("");
  const [client_name, setClientName] = useState("");
  const [code, setCode] = useState("");
  const [client_uuid, setClientUuid] = useState("");
  const [apiKey, setApiKey] = useState("");
  
  async function save() {
    if (props.match.params.uuid) {
      await Company.update(props.match.params.uuid, {
        name: name,
        code: code
      });
    } else {
      await Company.create({
        name: name,
        code: code
      });
    }
    props.history.push(`/companys`);
  }
  async function load(uuid) {
    try{
      let response = await Company.load(uuid);
      await setName(response.name);
      await setClientName(response.client.name);
      await setUuid(response.uuid);
      await setCode(response.code);
      await setClientUuid(response.client_uuid);
      await setApiKey(response.api_key);
    }catch(exception){
      console.log("cant load");
      console.log(exception);
    }
  }
  async function remove() {
    await Company.delete(uuid);
    props.history.push(`/companys`);
  }
  useEffect(() => {
    if (props.match.params.uuid) {
      load(props.match.params.uuid);
    }
  }, []);
  const deleteBtn = props.match.params.uuid ? (
    <Button className="button button--danger" onClick={remove}>
      Delete
    </Button>
  ) : (
    ""
  );

  return (
    <PageLayout>
      <h1>Company Details</h1>
      <div className="hr hr--darkest"></div>
      <h2>General Information</h2>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="Name"
            placeholder="Name"
            type="text"
            value={name}
            disabled={true}
            onChange={e => setName(e.target.value)}
          />
        </div>
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="UUID"
            placeholder="Uuid"
            type="text"
            value={uuid}
            disabled={true}
            readOnly
            onChange={e => setUuid(e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="Code"
            placeholder="Code"
            type="text"
            value={code}
            disabled={true}
            readOnly
            onChange={e => setCode(e.target.value)}
          />
        </div>
        <div className="col-xs-12 col-sm-6">
        <label><span className="label__span">Client</span></label>
        <Link to={`/client/${client_uuid}/`}>{client_name}</Link>
        </div>
      </div>
      <div className="hr"></div>
      <div className="row m-bot-10">
        <div className="col-xs-6">
          <div className="button-group">
            <a className="button button--plain" href="#" onClick={props.history.goBack}>
              Cancel
            </a>
          </div>
        </div>
      </div>
      { uuid  ? ( <UserList companyUuid={uuid} usePageLayout={false} /> ) : ( "" ) }
    </PageLayout>
  );
}
