import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Role from "lib/api/Role";
import {
  TextArea,
  TextInput,
  Checkbox
} from "components/common";
import PageLayout from "components/layouts/PageLayout";
export default function RoleForm(props) {
  const [uuid, setUuid] = useState("");
  const [name, setName] = useState("");
  const [permissions, setPermissions] = useState({});
  const [settings, setSettings] = useState({});
  const [created_at, setCreatedAt] = useState("");
  const [updated_at, setUpdatedAt] = useState("");
  const [company_users, setCompanyUsers] = useState([]);
  const [company, setCompany] = useState({ name: "", code: "" });
  // async function save() {
  //   if (props.match.params.uuid) {
  //     await User.update(props.match.params.uuid, {
  //       name: name,
  //       reference_id: referenceId,
  //       timesheets: timesheets,
  //       workorders: workorders,
  //       inventory: inventory,
  //       dailylogs: dailylogs,
  //       expenses: expenses,
  //       documents: documents
  //     });
  //   } else {
  //     await User.create({
  //       name: name,
  //       reference_id: referenceId,
  //       timesheets: timesheets,
  //       workorders: workorders,
  //       inventory: inventory,
  //       dailylogs: dailylogs,
  //       expenses: expenses,
  //       documents: documents
  //     });
  //   }
  //   props.history.push(`/users`);
  // }
  async function load(uuid) {
    const role = await Role.load(uuid);
    await setUuid(role.uuid);
    await setName(role.name);
    await setSettings(role.settings);
    await setPermissions(role.permissions);
    await setCreatedAt(role.created_at);
    await setUpdatedAt(role.updated_at);
    await setCompanyUsers(role.company_users);
    await setCompany(role.company);
  }
  async function remove() {
    await Role.remove(uuid);
    props.history.push(`/roles`);
  }
  useEffect(() => {
    if (props.match.params.uuid) {
      load(props.match.params.uuid);
    }
  }, []);
  const deleteBtn = props.match.params.id ? (
    <button variant="danger" onClick={remove}>
      delete
    </button>
  ) : (
    ""
  );
  const UserList = !company_users ? "" : company_users.map((user) => {
    return (
      <div className="row" key={user.uuid}>
        <div className="col-xs-12">
          <Link to={`/user/${user.uuid}/`}>
          {user.first_name} {user.last_name}
          </Link>
        </div>
      </div>
    )
  })
  return (
    <PageLayout>
      <h1>Role Details</h1>
      <div className="hr hr--darkest"></div>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="UUID"
            placeholder="UUID"
            type="text"
            value={uuid}
            disabled={true}
            readOnly
            onChange={e => setUuid(e.target.value)}
          />
        </div>
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="Name"
            placeholder="Name"
            type="text"
            value={name}
            disabled={true}
            onChange={e => setName(e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <TextArea
            label="Permissions"
            placeholder=""
            value={JSON.stringify(permissions, null, 2) || ""}
            disabled={true}
            onChange={e => setPermissions(e.target.value)}
          />
        </div>
        <div className="col-xs-12 col-sm-6">
          <TextArea
            label="Settings"
            placeholder=""
            value={JSON.stringify(settings, null, 2) || ""}
            disabled={true}
            onChange={e => setSettings(e.target.value)}
          />
        </div>
      </div>
      <div className="hr"></div>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="Company"
            value={company.name}
            disabled={true}
            onChange={e => setCompany(Object.assign(company, { name: e.target.value }))}
          />
        </div>
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="Code"
            value={company.code}
            disabled={true}
            onChange={e => setCompany(Object.assign(company, { code: e.target.value }))}
          />
        </div>
      </div>
      <div className="hr"></div>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <label><span className="label__span">Users</span></label>
          {UserList}
        </div>
      </div>
      <div className="hr"></div>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="Created At"
            value={created_at}
            disabled={true}
            onChange={e => setCreatedAt(e.target.value)}
          />
        </div>
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="Last Updated"
            value={updated_at}
            disabled={true}
            onChange={e => setUpdatedAt(e.target.value)}
          />
        </div>
      </div>
      <div className="row m-bot-100">
        <div className="col-xs-6">
          <div className="button-group">
            {/* <Button className="button" onClick={save}>
                Save
              </Button> */}
            <a className="button button--plain" href="#" onClick={props.history.goBack}>
              Cancel
            </a>
          </div>
        </div>
        <div className="col-xs-6 end-xs">
          <div className="button-group">
            {deleteBtn}
          </div>
        </div>
      </div>
    </PageLayout>
  );
}


