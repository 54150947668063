import Client from "lib/api/Client";
let Notifications = {
  get: async function(startIndex, limit, params){
    try{
    const authToken = await sessionStorage.getItem("token");
    let url = `${process.env.REACT_APP_API_BASE_URL}/notification/?offset=${startIndex}&limit=${limit}`;
    if (params) {
      params = new URLSearchParams(params).toString().replace("%2C", ",");
      if (params.length > 0)
      {
        url = url + `&${params}`;
      }
    }
    // let query_string = props ? `?${new URLSearchParams(props).toString()}`: "";
    const request = new Request(url, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: authToken
      })
    });
    const response = await fetch(request);
    
    if(response.status === 401){
      await sessionStorage.removeItem("token");
      throw new Error('Unauthorized');
    }

    const json = await response.json();
    const notifications = json.data;

    // Extract unique client_uuids from notifications
    const uniqueClientUuids = [...new Set(notifications.map(notification => notification.target_uuid))];

    // Fetch client information if there are any client_uuids
    let clients = [];
    if (uniqueClientUuids.length > 0) {
      const clientsResponse = await Client.get({ uuid: uniqueClientUuids.join(',') });
      clients = clientsResponse.data;
    }

    // Create a map of client_uuid to client name
    const clientUuidToName = clients.reduce((acc, client) => {
      acc[client.uuid] = client.name; // Assuming each client object has 'uuid' and 'name'
      return acc;
    }, {});

    // Enrich notifications with client names
    const enrichedNotifications = notifications.map(notification => ({
      ...notification,
      client_name: clientUuidToName[notification.target_uuid] || 'Unknown Client'
    }));

    json.data = enrichedNotifications;

    return { ...json };
  }catch(exception){
    console.log(exception);
  }
  },
  list: async function(startIndex, limit, search) {
    const authToken = await sessionStorage.getItem("token");
    var url = `${process.env.REACT_APP_API_BASE_URL}/support/notification/?offset=${startIndex}&limit=${limit}`;
    if (search) {
      url = url + `&search=${encodeURI(search)}`;
    }
    console.log(url);
    const request = new Request(url, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: authToken
      })
    });
    const response = await fetch(request);
    const json = await response.json();

    const notifications = json.data;
    const uniqueClientUuids = [...new Set(notifications.map(notification => notification.target_uuid))];

    let clients = [];
    if (uniqueClientUuids.length > 0) {
      const clientsResponse = await Client.get({ uuid: uniqueClientUuids.join(',') });
      clients = clientsResponse.data;
    }

    // Create a map of client_uuid to client name
    const clientUuidToName = clients.reduce((acc, client) => {
      acc[client.uuid] = client.name; // Assuming each client object has 'uuid' and 'name'
      return acc;
    }, {});

    const notificationsWithClientName = notifications.map(notification => ({
      ...notification,
      client_name: clientUuidToName[notification.client_uuid] || 'Unknown Client'
    }));

    return { data: notificationsWithClientName, total: json.pagination.total };
  },
  create: async function(notifications) {
    const authToken = await sessionStorage.getItem("token");
    const request = new Request(
      `${process.env.REACT_APP_API_BASE_URL}/notification/`,
      {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: authToken
        }),
        body: JSON.stringify(notifications)
      }
    );
    const response = await fetch(request);
    return await response.json();
  },
  update: async function(uuid, notification) {
    const authToken = await sessionStorage.getItem("token");
    const request = new Request(
      `${process.env.REACT_APP_API_BASE_URL}/notification/${uuid}`,
      {
        method: "PUT",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: authToken
        }),
        body: JSON.stringify(notification)
      }
    );
    const response = await fetch(request);
    return await response.json();
  },
  delete: async function(uuid) {
    try{
      const authToken = await sessionStorage.getItem("token");
      const request = new Request(
        `${process.env.REACT_APP_API_BASE_URL}/notification/${uuid}`,
        {
          method: "DELETE",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: authToken
          })
        }
      );
      return await fetch(request);
    }catch(exception){
      console.log("api error");
      console.log(exception);
    }
  },
  load: async function(uuid) {
    const authToken = await sessionStorage.getItem("token");
    var url = `${process.env.REACT_APP_API_BASE_URL}/notification/${uuid}`;
    const request = new Request(url, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: authToken
      })
    });
    const response = await fetch(request);
    const json = await response.json();
    return json;
  }
};
export default Notifications;