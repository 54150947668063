import React, { useState } from "react";
export const FlashMessageContext = React.createContext();
export function FlashMessageProvider(props) {
  const [flash_message, setFlashMessage] = useState("");
  const [type, setType] = useState("success");
  return (
    <FlashMessageContext.Provider
      value={{ flash_message, setFlashMessage, type, setType }}
    >
      {props.children}
    </FlashMessageContext.Provider>
  );
}
//export {CompanyContext, CompanyProvider}
