import React, { useState, useEffect, useContext } from "react";
import "./style.css";
import { NavLink } from "react-router-dom";
import packageInfo from '../../../../package.json';
import LeftNavLink from "components/common/LeftNavLink";
import Modal from "components/common/Modal";
import Company from "lib/api/Company";
import CompanyModal from "components/company/CompanyModal";
import { CompanyContext } from "lib/context/CompanyContext";
export default function LeftNav({ modalHandler, linkLabel, linkClass }) {
  const [showModal, setShowModal] = useState(false);
  const { company, setCompany } = useContext(CompanyContext);
  // async function load() {
  //   if (!company) {
  //     await setCompany({
  //       uuid: false,
  //       name: "System",
  //       reference_id: "SYSTEM",
  //       client: {
  //         name: "ComputerEase",
  //         reference_id: "CE"
  //       }
  //     });
  //   }
  //   if (!company.uuid) {
  //     console.log("NO COMPANY");
  //     var companies = await Company.list(0,50);
  //     // let response = await Company.list(0, 50, search);
  //     console.log(companies);
  //     if (companies.length) {
  //       await setCompany({
  //         uuid: false,
  //         name: "System",
  //         reference_id: "SYSTEM",
  //         client: {
  //           name: "ComputerEase",
  //           reference_id: "CE"
  //         }
  //       });
  //     } else {
  //       setShowModal(true);
  //     }
  //   }
  // }
  // useEffect(() => {
  //   load();
  // }, []);
  return (
    <div className="app-body__left-nav">
      <Modal show={showModal}>
        <CompanyModal setShowModal={setShowModal} />
      </Modal>
      <button
        className="login-toggle"
        onClick={() => {
          setShowModal(true);
        }}
      >
        <span className="login-toggle__gradient"></span>
        <span className="login-toggle__creds">
          <span className="creds--client-name">{company.client.name}</span>
          <span className="creds--company-name">
            {company.name ? company.name : "My Companies"}
          </span>
        </span>
      </button>
      {/* <NavLink to={`/dashboard`}>
        <LeftNavLink linkLabel="Dashboard" />
      </NavLink> */}
      <NavLink to={`/clients`}>
        <LeftNavLink linkLabel="Clients" />
      </NavLink>
      <NavLink to={`/companies`}>
        <LeftNavLink linkLabel="Companies" />
      </NavLink>
      <NavLink to={`/users`}>
        <LeftNavLink linkLabel="Users" />
      </NavLink>
      <NavLink to={`/groups`}>
        <LeftNavLink linkLabel="Groups" />
      </NavLink>
      <NavLink to={`/roles`}>
        <LeftNavLink linkLabel="Roles" />
      </NavLink>
      <NavLink to={`/notifications`}>
        <LeftNavLink linkLabel="Notifications" />
      </NavLink>
      {/* <NavLink to={`/employees`}>
        <LeftNavLink linkLabel="Employees" />
      </NavLink>
      <NavLink to={`/jobs`}>
        <LeftNavLink linkLabel="Jobs" />
      </NavLink>
      <NavLink to={`/phases`}>
        <LeftNavLink linkLabel="Phases" />
      </NavLink>
      <NavLink to={`/categories`}>
        <LeftNavLink linkLabel="Categories" />
      </NavLink> */}
      {/* <NavLink to={`/query`}>
        <LeftNavLink linkLabel="Database" />
      </NavLink>
      <NavLink to={`/api`}>
        <LeftNavLink linkLabel="Rest Api" />
      </NavLink>
      <NavLink to={`/websocket`}>
        <LeftNavLink linkLabel="Streaming Api" />
      </NavLink>
      <NavLink to={`/logs`}>
        <LeftNavLink linkLabel="Logs" />
      </NavLink> */}
      {!company.uuid && 
      <NavLink to={`/support/users`}>
        <LeftNavLink linkLabel="System Admins" />
      </NavLink>
      }
      {/*<NavLink to={`/logout`}>
        <LeftNavLink linkLabel="Logout" />
      </NavLink>
    */}    
      <div className="spacer"></div>
      <div className="version-info">
        {packageInfo.version}
      </div>
    </div>
  );
}
