import React, { useState } from "react";
export const StatusMessageContext = React.createContext();
export function StatusMessageProvider(props) {
  const [message, setMessage] = useState("");
  const [type, setType] = useState("success");
  return (
    <StatusMessageContext.Provider
      value={{ message, setMessage, type, setType }}
    >
      {props.children}
    </StatusMessageContext.Provider>
  );
}
//export {CompanyContext, CompanyProvider}
