import React, { useContext, Fragment } from 'react';
import { UserContext } from '../../../lib/context/UserContext';
import { Menu, Transition } from '@headlessui/react'
import { UserCircleIcon } from '@heroicons/react/24/outline'
import { NavLink } from "react-router-dom";

export default function AppProfileDropDown(props) {
  const { user } = useContext(UserContext);

  // Function to apply styling based on active state
  function classNames(...classes) {
    return classes.filter(Boolean).join(' '); 
  }

  return (    
    <div className="hidden md:flex md:items-center md:justify-end ml-auto mr-4">
        {/* Profile dropdown */}
        <Menu as="div" className="relative ml-3">
          <div>
          <Menu.Button className="flex items-center max-w-xs text-sm focus:outline-none">
            <span className="absolute -inset-1.5" />
            <span className="sr-only">Open user menu</span>
            <div className="mr-3 text-right">
              <div className="text-sm font-medium leading-5 text-gray-800">
                {user.first_name} {user.last_name}
              </div>
              <div className="text-xs font-medium leading-4 text-gray-600">
                {user.email}
              </div>
            </div>            
            <UserCircleIcon className="h-6 w-6 text-gray-800" aria-hidden="true" />
          </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              
                <Menu.Item key="logout">
                  {({ active }) => (                    
                    <NavLink to={`/logout`}>
                      <a
                        href="#"
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700'
                        )}
                      >
                        Logout
                      </a>
                    </NavLink>
                  )}
                </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
  );
}
