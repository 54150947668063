import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  TextInput,
  TextArea,
  Checkbox,
  CheckboxGroup,
} from "components/common";
import PageLayout from "components/layouts/PageLayout";
import Client from "lib/api/Client";
export default function ClientForm(props) {
  const [uuid, setUuid] = useState("");
  const [name, setName] = useState("");  
  const [code, setCode] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [dealer, setDealer] = useState("ComputerEase");
  const [type, setType] = useState("");
  const [companies, setCompanies] = useState([]);
  const [expiration, setExpiration] = useState("2022-01-01");
  const [created_at, setCreatedAt] = useState("");
  const [updated_at, setUpdatedAt] = useState("");
  const [permissions, setPermissions] =  useState({
    api: false,
    apr: false,
    ar: false,
    avid: false,
    bd: false,
    betapbc: false,
    cd: false,
    cepr: false,
    cr: false,
    docpw: false,
    dp: false,
    ec: false,
    fepo: false,
    fewo: false,
    gl: false,
    hf20210709: false,
    ic: false,
    infin: false,
    mb: false,
    nvpay: false,
    odbc: false,
    palm: false,
    pm: false,
    pr: false,
    qtool: false,
    re: false,
    rw: false,
    sk: false,
    sq: false,
    sub: false,
    ta: false,
    tc: false,
    tracker: false,
    trade: false,
    web: false,
    wedl: false,
    wejc: false,
    wepr: false,
    wewo: false,
    wl: false
  });
  const [settings, setSettings] =  useState({});
  const [notes, setNotes] =  useState("");
  const [enabled, setEnabled] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [newNotifications, setNewNotifications] = useState([]);
  const [newNotification, setNewNotification] = useState("");
  async function save() {
    if (props.match.params.uuid) {
      await Client.update(props.match.params.uuid, {
        name: name,
        code: code
      });
    } else {
      await Client.create({
        name: name,
        code: code
      });
    }
    props.history.push(`/clients`);
  }
  async function addNotification() {
    await setNotifications([...notifications, {uuid: Date.now(), message: newNotification}]);
    await setNewNotifications([...newNotifications, {message: newNotification}]);
    await setNewNotification("");
  }
  async function deleteNotification(uuid) {
    await setNotifications(notifications.filter((record)=>{return record.uuid != uuid}));
    await setNewNotifications(notifications.filter((record)=>{return record.uuid != uuid}));
  }
  async function load(uuid) {
    try{
      const response = await Client.load(uuid);
      const client = response.data;
      setName(client.name);
      setUuid(client.uuid);
      setCode(client.code);
      setApiKey(client.api_key);
      setPermissions(client.permissions);
      setSettings(client.settings);
      setType(client.type);
      setNotes(client.notes);
      setExpiration(client.expiration);
      setCompanies(client.companies);
      setUpdatedAt(client.updated_at);
      setCreatedAt(client.created_at);
    }catch(exception){
      console.log("cant load");
      console.log(exception);
    }
  }
  async function remove() {
    await Client.delete(uuid);
    props.history.push(`/clients`);
  }
  useEffect(() => {
    if (props.match.params.uuid) {
      load(props.match.params.uuid);
    }
  }, []);
  const deleteBtn = props.match.params.uuid ? (
    <Button className="button button--danger" onClick={remove}>
      Delete
    </Button>
  ) : (
    ""
  );
  const CompanyList = !companies ? "" : companies.map((company) => {
    return (
      <div className="row" key={company.uuid}>
        <div className="col-xs-12 col-sm-6">

        <Link to={`/company/${company.uuid}/`}>
                          {company.name}
                        </Link>
        </div>
      </div>
    )
  })

  // const companiesLink = props.match.params.uuid ? (
  //   <Link to={`/client/${props.match.params.uuid}/companies`}>Companies</Link>
  // ) : (
  //   ""
  // );
  const permissionCheckboxes = Object.keys(permissions).map((module)=>{
    return (
      <Checkbox
      key={module}
      title={module}
      checked={permissions[module]}
      onChange={e =>
        setPermissions({
          ...permissions,
          ...{[module]: !permissions[module]}
        })
      }
    />
    )
  })


  const NotificationRows =  notifications.map((record)=>{
    return (
      <tr key={record.uuid}>
        <td>{record.message}</td>
        <td><Button className="button button--danger" onClick={(e)=>{deleteNotification(record.uuid)}}>Delete</Button></td>
      </tr>
    )
  });

  return (
    <PageLayout>
      <h1>Client Details</h1>
      <div className="hr hr--darkest"></div>
      <h2>General Information</h2>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="Name"
            placeholder="Name"
            type="text"
            value={name}
            disabled={true}
            onChange={e => setName(e.target.value)}
          />
        </div>
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="UUID"
            placeholder="Uuid"
            type="text"
            value={uuid}
            disabled={true}
            readOnly
            onChange={e => setUuid(e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="Code (Customer Number)"
            placeholder="Code"
            type="text"
            value={code}
            disabled={true}
            readOnly
            onChange={e => setCode(e.target.value)}
          />
        </div>
        <div className="col-xs-12 col-sm-6">
        <TextInput
            label="Dealer"
            placeholder="Dealer"
            type="text"
            value={dealer}
            disabled={true}
            readOnly
            onChange={e => setDealer(e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <TextArea
            label="Notes (Internal)"
            placeholder="Enter Notes..."
            value={notes}
            disabled={true}
            onChange={e => setNotes(e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
        <label>
        <span className="label__span">Created At</span>
          <input type="text"
            value={created_at || ""}
            disabled={true}
          />
          </label>
        </div>
        <div className="col-xs-12 col-sm-6">
        <label>
          <span className="label__span">Updated At</span>
          <input type="text"
            value={updated_at || ""}
            disabled={true}
          />
        </label>
        </div>
      </div>      
      <div className="hr"></div>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
        <TextInput
            label="License Type"
            placeholder="License Type"
            type="text"
            value={type | ""}
            disabled={true}
            readOnly
            onChange={e => setDealer(e.target.value)}
          />
        </div>
        <div className="col-xs-12 col-sm-6">
        <label>
          <span className="label__span">Expiration</span>
          <input type="date"
            value={expiration || ""}
            onChange={e => setExpiration(e.target.value)}
            disabled={true}
          />
        </label>
        </div>
      </div>
      <div className="hr"></div>
      <div className="row">
        {/*<div className="col-xs-12 col-sm-6">
          <TextInput
            className="m-bot-0"
            label="API Key"
            placeholder="API Key"
            type="text"
            value={apiKey}
            disabled={true}
            readOnly
            onChange={e => setApiKey(e.target.value)}
          />
        </div>*/}
        <div className="col-xs-12 col-sm-6">
          <label><span className="label__span">API Information</span></label>
          <CheckboxGroup className="checkbox-group--col">
            <Checkbox
              title="API Enabled"
              checked={enabled}
              onChange={e =>
                setEnabled(!enabled)
              }
              disabled={true}
            />
          </CheckboxGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
        <TextArea
            label="Permissions"
            value={JSON.stringify(permissions, null, 2) || ""}
            disabled={true}
            onChange={e => setPermissions(e.target.value)}
          />
        </div>
        <div className="col-xs-12 col-sm-6">
          <TextArea
            label="Settings"
            value={JSON.stringify(settings, null, 2) || ""}
            disabled={true}
            onChange={e => setSettings(e.target.value)}
          />
        </div>
      </div>
      <div className="hr"></div>
      <div className="row">
      <div className="col-xs-12 col-sm-6">
          <label><span className="label__span">Companies</span></label>
          {CompanyList}
        </div>
      </div>
      <div className="hr hr--darkest m-top-20 m-bot-20"></div>
      <div className="row m-bot-100">
        <div className="col-xs-6">
          <div className="button-group">
            {/* <Button className="button" onClick={save}>
              Save
            </Button> */}
            <a className="button button--plain" href="#" onClick={props.history.goBack}>
              Cancel
            </a>
          </div>
        </div>
        <div className="col-xs-6 end-xs">
          {/* <div className="button-group">
            {deleteBtn}
          </div> */}
        </div>
      </div>
    </PageLayout>
  );
}
