import React from "react";
import "./style.css";
import LabelSpan from "../LabelSpan";
export default function TextInput({
  label, ...props}) {
  const inputLabel = label ? <LabelSpan>{label}</LabelSpan> : null;

  return (
    <label>
      {inputLabel}
      <textarea
        type="text"
        {...props}
      />
    </label>
  );
}
