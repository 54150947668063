let Client = {
  get: async function(props){
    const authToken = await sessionStorage.getItem("token");
    let query_string = props ? `?${new URLSearchParams(props).toString()}`: "";
    let url = `${process.env.REACT_APP_API_BASE_URL}/support/client${query_string}`
    const request = new Request(url, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: authToken,
      }),
    });
    const response = await fetch(request);
    const json = await response.json();
    return { data: json.data, total: json.pagination.total };
  },
  create: async function(client) {
    const authToken = await sessionStorage.getItem("token");
    const request = new Request(
      `${process.env.REACT_APP_API_BASE_URL}/support/client`,
      {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: authToken
        }),
        body: JSON.stringify(client)
      }
    );
    const response = await fetch(request);
    return await response.json();
  },
  update: async function(uuid, client) {
    const authToken = await sessionStorage.getItem("token");
    const request = new Request(
      `${process.env.REACT_APP_API_BASE_URL}/support/client/${uuid}`,
      {
        method: "PUT",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: authToken
        }),
        body: JSON.stringify(client)
      }
    );
    const response = await fetch(request);
    return await response.json();
  },
  delete: async function(uuid) {
    const authToken = await sessionStorage.getItem("token");
    const request = new Request(
      `${process.env.REACT_APP_API_BASE_URL}/support/client/${uuid}`,
      {
        method: "DELETE",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: authToken
        })
      }
    );
    const response = await fetch(request);
    return await response.json();
  },
  load: async function(uuid) {
    const authToken = await sessionStorage.getItem("token");
    var url = `${process.env.REACT_APP_API_BASE_URL}/support/client/${uuid}`;
    const request = new Request(url, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: authToken
      })
    });
    const response = await fetch(request);
    const json = await response.json();
    return json;
  }
};
export default Client;