const Session = {
  create: async function (payload) {
    const request = new Request(
      `${process.env.REACT_APP_API_BASE_URL}/support/session`,
      {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(payload),
      }
    );
    const response = await fetch(request);
    return await response.json();
  },
  sendAuthorization: async function (payload) {
    const request = new Request(
      `${process.env.REACT_APP_API_BASE_URL}/support/user/authorization`,
      {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(payload),
      }
    );
    const response = await fetch(request);
    return await response.json();
  },
  delete: async function (uuid) {
    const auth_token = await sessionStorage.getItem("token");
    const request = new Request(
      `${process.env.REACT_APP_API_BASE_URL}/support/session`,
      {
        method: "DELETE",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: auth_token,
        }),
      }
    );
    const response = await fetch(request);
    await sessionStorage.removeItem("token");
    return await response.json();
  },
  get: async function () {
    const auth_token = await sessionStorage.getItem("token");
    let url = `${process.env.REACT_APP_API_BASE_URL}/support/session`;
    const request = new Request(url, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: auth_token,
      }),
    });
    const response = await fetch(request);
    const json = await response.json();
    return json;
  },
};

export default Session;