import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import Login from "./components/account/Login";
import ClientList from "./components/client/ClientList";
import ClientForm from "./components/client/ClientForm";
import CompanyList from "./components/company/CompanyList";
import CompanyForm from "./components/company/CompanyForm";
import UserList from "./components/user/UserList";
import UserForm from "./components/user/UserForm";
import GroupList from "./components/group/GroupList";
import GroupForm from "./components/group/GroupForm";
import RoleList from "./components/role/RoleList";
import RoleForm from "./components/role/RoleForm";
import SupportUserList from "./components/support/SupportUserList";
import SupportUserForm from "./components/support/SupportUserForm";
import NotificationList from "./components/notification/NotificationList";
import NotificationForm from "./components/notification/NotificationForm";
import NotificationBatchForm from "./components/notification/NotificationBatchForm";
import { CompanyProvider } from "./lib/context/CompanyContext";
import { UserProvider } from "lib/context/UserContext";
import { StatusMessageProvider } from "./lib/context/StatusMessageContext";
import { FlashMessageProvider } from "./lib/context/FlashMessageContext";
// import "./App.scss";
const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      sessionStorage.getItem("token") ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

function App() {
  return (
    <div>
      <CompanyProvider>
        <UserProvider>
      <FlashMessageProvider>
        <StatusMessageProvider>
          <Router>
            <Switch>
            <Route exact path="/" component={Login} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/user/:support_user_uuid/login/:authorization_code" component={Login} />
              <Route exact path="/" component={Login} />
              <Route exact path="/logout" component={Login} />
              <ProtectedRoute exact path="/clients" component={ClientList} />
              <ProtectedRoute exact path="/client" component={ClientForm} />
              <ProtectedRoute
                exact
                path="/client/:uuid"
                component={ClientForm}
              />
              <ProtectedRoute exact path="/companies" component={CompanyList} />
              <ProtectedRoute exact path="/company" component={CompanyForm} />
              <ProtectedRoute
                exact
                path="/company/:uuid"
                component={CompanyForm}
              />
              <ProtectedRoute exact path="/notifications" component={NotificationList} />
              <ProtectedRoute exact path="/notification" component={NotificationBatchForm} />
              <ProtectedRoute exact path="/notification/:uuid" component={NotificationForm} />
              <ProtectedRoute exact path="/users" component={UserList} />
              <ProtectedRoute exact path="/user/:uuid" component={UserForm} />
              <ProtectedRoute exact path="/groups" component={GroupList} />
              <ProtectedRoute exact path="/group/:uuid" component={GroupForm} />
              <ProtectedRoute exact path="/roles" component={RoleList} />
              <ProtectedRoute exact path="/role/:uuid" component={RoleForm} />
              <ProtectedRoute exact path="/support/users" component={SupportUserList} />
              <ProtectedRoute exact path="/support/user" component={SupportUserForm} />
              <ProtectedRoute exact path="/support/user/:uuid" component={SupportUserForm} />
            </Switch>
          </Router>
        </StatusMessageProvider>
        </FlashMessageProvider>
        </UserProvider>
      </CompanyProvider>
    </div>
  );
}
export default App;
