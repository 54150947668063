import React, { useState, useEffect, useContext } from "react";
import SupportUser from "lib/api/SupportUser";
import {
  TextInput,
  Button,
} from "components/common";
import { CompanyContext } from "lib/context/CompanyContext";
import PageLayout from "components/layouts/PageLayout";
export default function SupportUserForm(props) {
  const { company } = useContext(CompanyContext);
  const [uuid, setUuid] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [created_at, setCreatedAt] = useState("");
  const [updated_at, setUpdatedAt] = useState("");
  async function create() {
      await SupportUser.create({
        email: email,
        first_name: first_name,
        last_name: last_name,
      });
    props.history.push(`/support/users`);
  }
  async function load(uuid) {
    const support_user = await SupportUser.load(uuid);
    await setUuid(support_user.uuid);
    await setEmail(support_user.email);
    await setFirstName(support_user.first_name);
    await setLastName(support_user.last_name);
    await setCreatedAt(support_user.created_at);
    await setUpdatedAt(support_user.updated_at);
  }
  async function remove() {
    await SupportUser.delete(uuid);
    props.history.push(`/support/users`);
  }
  useEffect(() => {
    if(company.uuid){
      props.history.push(`/clients`);
    }
    if (props.match.params.uuid) {
      load(props.match.params.uuid);
    }
  }, [company]);
  const actionBtn = props.match.params.uuid ? (
    <Button className="button--danger" onClick={remove}>
      Delete
    </Button>
  ) : (
    <Button onClick={create}>
        Create
    </Button> 
  );
  return (
    <PageLayout>
      <h1>Support User Details</h1>
      <div className="hr hr--darkest"></div>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="Email"
            placeholder="Email"
            type="text"
            value={email}
            disabled={!!props.match.params.uuid}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="UUID"
            type="text"
            value={uuid}
            disabled={true}
            readOnly
            onChange={e => setUuid(e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="First Name"
            placeholder="First Name"
            type="text"
            value={first_name}
            disabled={!!props.match.params.uuid}
            onChange={e => setFirstName(e.target.value)}
          />
        </div>
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="Last Name"
            placeholder="Last Name"
            type="text"
            value={last_name}
            disabled={!!props.match.params.uuid}
            onChange={e => setLastName(e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="Created At"
            value={created_at}
            disabled={true}
            onChange={e => setCreatedAt(e.target.value)}
          />
        </div>
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="Last Updated"
            value={updated_at}
            disabled={true}
            onChange={e => setUpdatedAt(e.target.value)}
          />
        </div>
      </div>
      <div className="row m-bot-100">
        <div className="col-xs-6">
          <div className="button-group">
            <a className="button button--plain" href="#" onClick={props.history.goBack}>
              Cancel
            </a>
          </div>
        </div>
        <div className="col-xs-6 end-xs">
          <div className="button-group">
            {actionBtn}
          </div>
        </div>
      </div>
    </PageLayout>
  );
}
