import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import SupportUser from "lib/api/SupportUser";
import { InfiniteLoader, AutoSizer, Column, Table } from "react-virtualized";
import "react-virtualized/styles.css";
import PageLayout from "components/layouts/PageLayout";
import Button from "components/common/Button";
import ButtonGroup from "components/common/ButtonGroup";
import TextInput from "components/common/TextInput";
import { CompanyContext } from "lib/context/CompanyContext";

export default function SupportUserList(props) {
  const { company } = useContext(CompanyContext);

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(1);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const hasNextPage = data.length < total;
  async function loadNextPage({ startIndex, stopIndex }) {
    let params = {};
    if(company.uuid || search){
      if(company.uuid){
        params["company_uuid"] = company.uuid;
      }
      if(search){
        params["search"] = search;
      }
    }else{
      params = false;
    }
    await setLoading(true);
    let limit = stopIndex - startIndex + 1;
    let results = await SupportUser.list(startIndex, limit, params);
    await setTotal(results.total);
    await setData([...data, ...results.data]);
    await setLoading(false);
  }

  async function load() {
    let params = {};
    if(company.uuid || search){
      if(company.uuid){
        // params["company_uuid"] = company.uuid;
        props.history.push(`/clients`);
      }
      if(search){
        params["search"] = search;
      }
    }else{
      params = false;
    }
    await setLoading(true);
    let results = await SupportUser.list(0, 30, params);
    // setTotal(0);
    // setData([]);
    await setTotal(results.total);
    await setData(results.data);
    await setLoading(false);
  }
  const loadMoreRows = loading ? () => {} : loadNextPage;
  const isRowLoaded = ({ index }) => !hasNextPage || index < data.length;

  useEffect(() => {
    load();
    return ()=>{setLoading(false)};
  }, [company]);
  return (
    <PageLayout>
    <h1>System Admins</h1>
    <div className="hr hr--darkest"></div>
    <div className="row">
      <div className="col-xs-6">
        <ButtonGroup>
          <Link className="button" to="/support/user">
            Create
          </Link>
        </ButtonGroup>
      </div>
      <div className="col-xs-6 end-xs">
        <ButtonGroup>
          <div className="input-group">
            <TextInput
              className="m-bot-0"
              label={null}
              placeholder="Search..."
              value={search}
              disabled={false}
              onChange={e => setSearch(e.target.value)}
            />
            <Button className="button--icon" onClick={load}>
              Search
            </Button>
          </div>
        </ButtonGroup>
        <div>{data.length} of {total} loaded</div>
      </div>
    </div>
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        rowCount={total}
      >
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer>
            {({ height, width }) => {
              return (
                <Table
                  headerHeight={40}
                  width={width}
                  height={height-120}
                  rowCount={total}
                  rowHeight={40}
                  disableHeader={false}
                  rowGetter={({ index }) => data[index] || {}}
                  onRowsRendered={onRowsRendered}
                  ref={registerChild}
                >
                  <Column
                    label="Email"
                    dataKey="email"
                    width={width / 3}
                    cellRenderer={({ rowData, cellData }) => {
                      if (cellData === undefined) {
                        return "Loading...";
                      }
                      return cellData;
                    }}
                  />
                  <Column
                    label="Name"
                    dataKey="last_name"
                    width={width / 3}
                    cellRenderer={({ rowData, cellData }) => {
                      if (cellData === undefined) {
                        return "Loading...";
                      }
                      return `${rowData.first_name} ${rowData.last_name} `;
                    }}
                  />
                  <Column
                    label="Created"
                    dataKey="created_at"
                    width={width / 4}
                    cellRenderer={({ rowData, cellData }) => {
                      if (cellData === undefined) {
                        return "Loading...";
                      }
                      return new Date(cellData).toLocaleString() 
                    }}
                  />
                  <Column
                    label=""
                    dataKey="uuid"
                    width={width / 3}
                    cellRenderer={({ rowData }) => {
                      if (!rowData.uuid) {
                        return "";
                      }
                      return (
                        <ButtonGroup>
                        <Link className='button m-bot-0' to={`/support/user/${rowData.uuid}/`}>
                          View
                        </Link>
                        </ButtonGroup>
                      );
                    }}
                  />
                </Table>
              );
            }}
          </AutoSizer>
        )}
      </InfiniteLoader>
    </PageLayout>
  );
}
