import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Group from "lib/api/Group";
import {
  TextInput,
} from "components/common";

import PageLayout from "components/layouts/PageLayout";
export default function GroupForm(props) {
  const [uuid, setUuid] = useState("");
  const [name, setName] = useState("");
  const [access_codes, setAccessCodes] = useState([]);
  const [created_at, setCreatedAt] = useState("");
  const [updated_at, setUpdatedAt] = useState("");
  const [company_users, setCompanyUsers] = useState([]);
  const [company, setCompany] = useState({name:"",code:""});
  async function load(uuid) {
    const group = await Group.load(uuid);
    await setUuid(group.uuid);
    await setName(group.name);
    await setAccessCodes(group.access_codes);

    await setCreatedAt(group.created_at);
    await setUpdatedAt(group.updated_at);
    await setCompanyUsers(group.company_users);
    await setCompany(group.company);
  }
  async function remove() {
    await Group.remove(uuid);
    props.history.push(`/groups`);
  }
  useEffect(() => {
    if (props.match.params.uuid) {
      load(props.match.params.uuid);
    }
  }, []);
  const deleteBtn = props.match.params.id ? (
    <button variant="danger" onClick={remove}>
      delete
    </button>
  ) : (
    ""
  );
  const UserList = !company_users ? "" : company_users.map((user) => {
    return (
      <div className="row" key={user.uuid}>
        <div className="col-xs-12 col-sm-6">

        <Link to={`/user/${user.uuid}/`}>
                          {user.first_name} {user.last_name}
                        </Link>
        </div>
      </div>
    )
  })
  return (
    <PageLayout>
      <h1>Group Details</h1>
      <div className="hr hr--darkest"></div>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="UUID"
            placeholder="UUID"
            type="text"
            value={uuid}
            disabled={true}
            readOnly
            onChange={e => setUuid(e.target.value)}
          />
        </div>
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="Name"
            placeholder="Name"
            type="text"
            value={name}
            disabled={true}
            onChange={e => setName(e.target.value)}
          />
        </div>
      </div>
      <div className="hr"></div>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="Company"
            value={company.name}
            disabled={true}
            onChange={e => setCompany(Object.assign(company, {name:e.target.value}))}
          />
        </div>
      </div>
      <div className="hr"></div>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="Access Codes"
            placeholder=""
            type="text"
            value={access_codes}
            disabled={true}
            readOnly
            onChange={e => setAccessCodes(e.target.value)}
          />
        </div>
        <div className="col-xs-12 col-sm-6">
          <label><span className="label__span">Users</span></label>
          {UserList}
        </div>
      </div>
      <div className="hr"></div>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="Created At"
            value={created_at}
            disabled={true}
            onChange={e => setCreatedAt(e.target.value)}
          />
        </div>
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="Last Updated"
            value={updated_at}
            disabled={true}
            onChange={e => setUpdatedAt(e.target.value)}
          />
        </div>
      </div>
      <div className="row m-bot-100">
        <div className="col-xs-6">
          <div className="button-group">
            {/* <Button className="button" onClick={save}>
                Save
              </Button> */}
            <a className="button button--plain" href="#" onClick={props.history.goBack}>
              Cancel
            </a>
          </div>
        </div>
        <div className="col-xs-6 end-xs">
          <div className="button-group">
            {deleteBtn}
          </div>
        </div>
      </div>
    </PageLayout>
  );
}
