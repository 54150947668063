import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import User from "lib/api/User";
import { InfiniteLoader, AutoSizer, Column, Table } from "react-virtualized";
import "react-virtualized/styles.css";
import PageLayout from "components/layouts/PageLayout";
import Button from "components/common/Button";
import ButtonGroup from "components/common/ButtonGroup";
import TextInput from "components/common/TextInput";
import { CompanyContext } from "lib/context/CompanyContext";

export default function UserList(props) {  
  const { company: contextCompany } = useContext(CompanyContext);
  const companyUuid = props.companyUuid || contextCompany.uuid;
  const usePageLayout = props.usePageLayout !== undefined ? props.usePageLayout : true;
  
  const [records, setRecords] = useState([]);
  const [total, setTotal] = useState(1);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(false);

  const [recordsPerPage, setRecordsPerPage] = useState(100);

  async function load() {
    let params = {};
    if(companyUuid || search){
      if(companyUuid){
        params["company_uuid"] = companyUuid;
      }
      if(search){
        params["search"] = search;
      }
    }else{
      params = false;
    }
    await setLoading(true);
    let results = await User.list(0, recordsPerPage, params);
    
    await setTotal(results.total);
    await setRecords(results.data);
    await setLoading(false);
  }

  async function loadNextPage({ startIndex, stopIndex }) {
    if (loading) return;
    await setLoading(true);

    let params = {};
    if(companyUuid || search){
      if(companyUuid){
        params["company_uuid"] = companyUuid;
      }
      if(search){
        params["search"] = search;
      }
    }
    else
    {
      params = false;
    }
    let response = await User.list(startIndex, Math.max(recordsPerPage, stopIndex - startIndex + 1), params);
    if (response.total !== total) {
      await setTotal(response.total);
    }
    const newData = response.data;
    setRecords(prevRecords => [...prevRecords, ...newData]);
    await setLoading(false);
  }
  
  const hasNextPage = records.length < total;
  const loadMoreRows = loading ? () => {} : loadNextPage;
  const isRowLoaded = ({ index }) => !hasNextPage || index < records.length;

  useEffect(() => {
    load();
    return ()=>{setLoading(false)};
  }, [companyUuid]);
  const content = (
    <div style={{ height: '100%', width: '100%' }}>
    <h1>Users</h1>
    <div className="hr hr--darkest"></div>
    <div className="row">
      <div className="col-xs-6">
        {/* <ButtonGroup>
          <Link className="button" to="/user">
            Create
          </Link>
        </ButtonGroup> */}
      </div>
      <div className="col-xs-6 end-xs">
        <ButtonGroup>
          <div className="input-group">
            <TextInput
              className="m-bot-0"
              label={null}
              placeholder="Search..."
              value={search}
              disabled={false}
              onChange={e => setSearch(e.target.value)}
            />
            <Button className="button--icon" onClick={load}>
              Search
            </Button>
          </div>
        </ButtonGroup>
        <div>{records.length} of {total} loaded</div>
      </div>
    </div>
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        rowCount={total}
      >
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer>
            {({ height, width }) => {
              return (
                <Table
                  headerHeight={40}
                  width={width}
                  height={ usePageLayout ? height - 120 : 600 }
                  rowCount={total}
                  rowHeight={40}
                  disableHeader={false}
                  rowGetter={({ index }) => records[index] || {}}
                  onRowsRendered={onRowsRendered}
                  ref={registerChild}
                >
                  <Column
                    label="Email"
                    dataKey="email"
                    width={width / 4}
                    cellRenderer={({ rowData, cellData }) => {
                      if (cellData === undefined) {
                        return "Loading...";
                      }
                      return cellData;
                    }}
                  />
                  {/* <Column
                    label="Phone"
                    dataKey="mobile_phone"
                    width={width / 6}
                    cellRenderer={({ rowData, cellData }) => {
                      if (cellData === undefined) {
                        return "Loading...";
                      }
                      return rowData.mobile_phone;
                    }}
                  /> */}
                  <Column
                    label="Name"
                    dataKey="last_name"
                    width={width / 4}
                    cellRenderer={({ rowData, cellData }) => {
                      if (cellData === undefined) {
                        return "Loading...";
                      }
                      return `${rowData.first_name} ${rowData.last_name} `;
                    }}
                  />
                  <Column
                    label="Company"
                    dataKey="company"
                    width={width / 5}  
                    cellRenderer={({ rowData, cellData }) => {
                      if (cellData === undefined) {
                        return "Loading...";
                      }
                      // console.log(cellData);
                      return cellData.name ? `${cellData.name}` : "";
                    }}
                  />
                                    <Column
                    label="Created"
                    dataKey="created_at"
                    width={width / 5}
                    cellRenderer={({ rowData, cellData }) => {
                      if (!cellData) {
                        return "Loading...";
                      }
                      return new Date(cellData).toLocaleString() 
                    }}
                  />
                  <Column
                    label=""
                    dataKey="uuid"
                    width={width / 4}
                    cellRenderer={({ rowData }) => {
                      if (!rowData.uuid) {
                        return "";
                      }
                      return (
                        <ButtonGroup>
                        <Link className='button m-bot-0' to={`/user/${rowData.uuid}/`}>
                          View
                        </Link>
                        </ButtonGroup>
                      );
                    }}
                  />
                </Table>
              );
            }}
          </AutoSizer>
        )}
      </InfiniteLoader>
    </div>
  );

  return usePageLayout ? (
    <PageLayout>
      { content }
    </PageLayout>
  ) : content;
}
