import React, { useContext } from "react";
import { CompanyContext } from "lib/context/CompanyContext";
import CompanyList from "./CompanyList";
import ButtonGroup from "components/common/ButtonGroup";
import Button from "components/common/Button";

export default function CompanyModal({ setShowModal }) {
  const { company } = useContext(CompanyContext);
  return (
    <div className="app-body__page">
      <h1 className="m-bot-0">Companies</h1>
      <p className="p--small">
        Logged in as: {company.client.name} - {company.name}
      </p>
      <div className="hr hr--darkest m-bot-20"></div>
      <CompanyList setShowModal={setShowModal} />
      <div className="hr hr--darkest m-top-10 m-bot-20"></div>
      <div className="row middle-xs">
        <div className="col-xs-4">
          <ButtonGroup>
            <Button
              onClick={() => {
                setShowModal(false);
              }}
            >
              Close
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </div>
  );
}
