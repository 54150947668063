import React, { useState, useEffect, useContext} from "react";
import { Link } from "react-router-dom";
import Notification from "lib/api/Notification";
import { InfiniteLoader, AutoSizer, Column, Table } from "react-virtualized";
import "react-virtualized/styles.css";
import PageLayout from "components/layouts/PageLayout";
import { Button, TextInput, ButtonGroup, Checkbox} from "components/common";
import { CompanyContext } from "lib/context/CompanyContext";

export default function NotificationList(props) {
  const { company } = useContext(CompanyContext);
  const [records, setRecords] = useState([]);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [selectAll, setSelectAll] = useState(false);

  const [recordsPerPage, setRecordsPerPage] = useState(100);  

  async function load() {
    await setLoading(true);
    try{
      let params = search ? {
        search: search,
        search_fields: "title,message"
      }:{};
      if(company.uuid && company.client.uuid !== undefined){
        params["target_uuid"] = company.client.uuid;
      }
      let response = await Notification.get(0, recordsPerPage, params);
      switch(response.status){
        case 200:
            let body = response;
            const newData = body.data;
            setRecords(prevRecords => [...prevRecords, ...newData]);
            await setTotal(response.pagination.total);
          break;
        case 401:
          props.history.push(`/`);
          break;
        default:
          console.log('An unhandled api error occured.');
          console.log(response);
      }
    }catch(exception){
      console.log(exception);
    }
    await setLoading(false);
  }
  function isRowLoaded({ index }) {
    return !!records[index];
  }
  async function loadMoreRows({ startIndex, stopIndex }) {
    try{      
      if (loading) return;
      await setLoading(true);

      let params = search ? {
        search: search,
        search_fields: "title,message"
      }:{};
      let response = await Notification.get(startIndex, Math.max(recordsPerPage, stopIndex - startIndex + 1), params);
      
      switch(response.status){
        case 200:
            let body = await response.json();
            const newData = body.data;
            setRecords(prevRecords => [...prevRecords, ...newData]);
            await setTotal(body.pagination.total);
          break;
        case 401:
          props.history.push(`/`);
          break;
        default:
          console.log('An unhandled api error occured.');
          console.log(response);
      }
    }catch(exception){
      console.log(exception);
    }
    await setLoading(false);
  }

  useEffect(() => {
    load();
    return ()=>{setLoading(false)};
  }, [company]);

  const handleRowSelect = (rowData) => {
    const newSelected = { ...selected, [rowData.uuid]: !selected[rowData.uuid] };
    setSelected(newSelected);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelected({});
    } else {
      const newSelected = {};
      records.forEach(record => {
        newSelected[record.uuid] = true;
      });
      setSelected(newSelected);
    }
    setSelectAll(!selectAll);
  };

  const handleDeleteSelected = async () => {
    const numSelected = Object.keys(selected).filter(uuid => selected[uuid]).length;
    if (numSelected === 0) {
      alert("No notifications selected.");
      return;
    }
    
    const confirmDelete = window.confirm(`Delete ${numSelected} selected notification(s)?`);
    if (!confirmDelete) {
      return;
    }
  
    let successCount = 0;
    let errorCount = 0;
    let failedDeletes = [];
  
    for (let uuid in selected) {
      if (!selected[uuid]) continue; // Skip if not selected
  
      try {
        var response = await Notification.delete(uuid); // Assuming Notification.delete is a promise
        if (response.status !== 200) {
          console.error("Error deleting notification with UUID:", uuid, response.status, response.message);
          errorCount++;
          failedDeletes.push(uuid); // Keep track of failed deletions for potential retry logic
        }
        else
        {
          successCount++;
        }
      } catch (error) {
        console.error("Error deleting notification with UUID:", uuid, error);
        errorCount++;
        failedDeletes.push(uuid); // Keep track of failed deletions for potential retry logic
      }
    }
  
    if (errorCount > 0) {
      alert(`Failed to delete ${errorCount} notification(s). Please try again.`);
      // Update selected state to only keep failed ones if you want to allow retrying
      const updatedSelected = failedDeletes.reduce((acc, curr) => ({ ...acc, [curr]: true }), {});
      setSelected(updatedSelected);
    } else {
      alert("All selected notifications were successfully deleted.");
      setSelected({});
    }
  
    setSelectAll(false);
    
    setRecords(prevRecords => prevRecords.filter(record => !selected[record.uuid]));
  };

  return (
    <PageLayout>
      <h1>Notifications</h1>
      <div className="hr hr--darkest"></div>
      <div className="row">
        <div className="col-xs-6">    
          <ButtonGroup>
            <Link className="button" to="/notification">
              Create
            </Link>
          </ButtonGroup>
          <ButtonGroup className="ml-3">
            <Button onClick={handleDeleteSelected} disabled={Object.keys(selected).filter(uuid => selected[uuid]).length === 0}>Delete Selected</Button>
          </ButtonGroup>
        </div>
        <div className="col-xs-6 end-xs">
        <ButtonGroup>
            <div className="input-group">
              <TextInput
                className="m-bot-0"
                label={null}
                placeholder="Search..."
                value={search}
                disabled={false}
                onChange={e => setSearch(e.target.value)}
              />
              <Button className="button--icon" onClick={load}>
                Search
              </Button>
              <div>{records.length} of {total} loaded</div>
            </div>
          </ButtonGroup>
        </div>
      </div>

      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        rowCount={total}
      >
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer>
            {({ height, width }) => {
              let columns = 3;
              return (
                <Table
                  headerHeight={40}
                  width={width}
                  height={height-120}
                  rowCount={total}
                  rowHeight={40}
                  disableHeader={false}
                  rowGetter={({ index }) => records[index] || {}}
                  onRowsRendered={onRowsRendered}
                  ref={registerChild}
                >
                  <Column
                  label={<Checkbox checked={selectAll} onChange={handleSelectAll} />}
                  dataKey=""
                  width={ 30 }
                  cellRenderer={({ rowData }) => (
                    <Checkbox checked={!!selected[rowData.uuid]} onChange={() => handleRowSelect(rowData)} />
                  )}
                />
                  <Column
                    label="Client"
                    dataKey="client_name"
                    width={width / 5}
                    cellRenderer={({ rowData, cellData }) => {
                      const clientName = rowData.client_name; // Access the client name
                      const targetUuid = rowData.target_uuid;

                      if (!cellData) {
                        return "Loading....";
                      }
                      return <Link to={`/client/${targetUuid}/`}>
                      {clientName}
                    </Link>
                    }}
                  />
                  <Column
                    label="Title"
                    dataKey="title"
                    width={width / 12}
                    cellRenderer={({ rowData, cellData }) => {
                      if (!cellData) {
                        return "Loading....";
                      }
                      const toProperCase = str =>
                        str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());

                      const properCaseTitle = toProperCase(cellData);
                      return properCaseTitle;
                    }}
                  />
                  <Column
                    label="Message"
                    dataKey="message"
                    width={width / 3}
                    cellRenderer={({ rowData, cellData }) => {
                      if (!cellData) {
                        return "Loading....";
                      }
                      return cellData;
                    }}
                  />

                  <Column
                    label="Created"
                    dataKey="created_at"
                    width={width / 8}
                    cellRenderer={({ rowData, cellData }) => {
                      if (!cellData) {
                        return "Loading....";
                      }
                      return new Date(cellData).toLocaleString(); 
                    }}
                  />
                  <Column
                    label=""
                    dataKey="uuid"
                    width={width / 8}
                    cellRenderer={({ rowData, cellData }) => {
                      if (!cellData) {
                        return "Loading...";
                      }
                      return (
                        <ButtonGroup>
                        <Link className="button m-bot-0" to={`/notification/${encodeURIComponent(cellData)}`}>
                          View
                        </Link>
                      </ButtonGroup>
                      );
                    }}
                  />
                </Table>
              );
            }}
          </AutoSizer>
        )}
      </InfiniteLoader>
    </PageLayout>
  );
}
