let User = {
  list: async function(startIndex, limit, params) {
    const authToken = await sessionStorage.getItem("token");
    let url = `${process.env.REACT_APP_API_BASE_URL}/support/company-user?offset=${startIndex}&limit=${limit}`;
    if (params) {
      params = new URLSearchParams(params).toString();
      url = url + `&${params}`;
    }
    const request = new Request(url, {
      method: "GET",
      mode: "cors",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: authToken
      })
    });
    const response = await fetch(request);
    const json = await response.json();
    return { data: json.data, total: json.pagination.total };
  },
  create: async function(user) {
    const authToken = await sessionStorage.getItem("token");
    const request = new Request(
      `${process.env.REACT_APP_API_BASE_URL}/support/company-user`,
      {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: authToken
        }),
        body: JSON.stringify(user)
      }
    );
    const response = await fetch(request);
    return await response.json();
  },
  update: async function(uuid, user) {
    const authToken = await sessionStorage.getItem("token");
    const request = new Request(
      `${process.env.REACT_APP_API_BASE_URL}/support/company-user/${uuid}`,
      {
        method: "PUT",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: authToken
        }),
        body: JSON.stringify(user)
      }
    );
    const response = await fetch(request);
    return await response.json();
  },
  delete: async function(uuid) {
    const authToken = await sessionStorage.getItem("token");
    const request = new Request(
      `${process.env.REACT_APP_API_BASE_URL}/support/user/${uuid}`,
      {
        method: "DELETE",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: authToken
        })
      }
    );
    const response = await fetch(request);
    return await response.json();
  },
  load: async function(uuid) {
    const authToken = await sessionStorage.getItem("token");
    var url = `${process.env.REACT_APP_API_BASE_URL}/support/company-user/${uuid}`;
    const request = new Request(url, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: authToken
      })
    });
    const response = await fetch(request);
    const json = await response.json();
    return json.data;
  }
};

export default User;