import packageInfo from '../../../../package.json';
import React, { useState, useEffect, useContext } from "react";
import Validation from "components/common/Validation";
import logo from "./logo.svg";
import Button from "components/common/Button";
import ButtonGroup from "components/common/ButtonGroup";
import TextInput from "components/common/TextInput";
import Session from "lib/api/Session.js";
import { UserContext, UserProvider } from 'lib/context/UserContext';
import User from 'lib/api/User';

export default function Login(props) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState();
  
  const { setUser } = useContext(UserContext);

  async function submit() {
    await setLoading(true);
    let response = await Session.sendAuthorization({
      email: email,
    });
    switch (response.status) {
      case 200:
        setError(null);
        setSuccess(response.message);
        break;
      default:
        setError("Something went wrong. Please try again.");
    }
    await setLoading(false);
  }

  async function login(user_uuid, authorization_code){
    await setLoading(true);
    let response = await Session.create({
      support_user_uuid: user_uuid,
      authorization_code: authorization_code
    });

    if (response.status === 401)
    {
      setError(response.message);
      await setLoading(false);
      return;
    }
    else
    {
      await sessionStorage.setItem("token",response.data.token);
      await sessionStorage.setItem("user",JSON.stringify(response.data.user));
      setUser(response.data.user);
      
      props.history.push(`/clients`); 
    }
  }

  async function logout(){
    let response = await Session.delete();
    console.log(response);
    await sessionStorage.removeItem("token");
    await sessionStorage.removeItem("user");
    setUser(null);
    
    props.history.push(`/`);
  }

  useEffect(() => {
    if(props.location.pathname === '/logout'){
      logout();
    }else if(props.match.params.support_user_uuid && props.match.params.authorization_code){
      login(props.match.params.support_user_uuid, props.match.params.authorization_code)
    }
    return ()=>{
      setSuccess();
      setError();
      setLoading(false);
    };
  }, []);
  return (
    <div className="app">
      <div className="app-body">
        <div className="app-body__content app-body__content--gradient">
          <div className="app-body__page app-body__page--sm">
            <div className="m-bot-auto m-top-auto">
                <div className="row m-top-50">
                  <div className="col-xs-12 center-xs">
                    <img src={logo} alt="logo" style={{ maxHeight: "87px" }} />
                  </div>
                  <div class="app-title">
                    Field Support Portal
                  </div>
                </div>
                <div className="row">
                <div className="col-xs-12">
                {success && <Validation className="success">{success}</Validation>}
                {error && <Validation className="danger">{error}</Validation>}
                </div>
                  <div className="col-xs-12">
                    <TextInput
                      label="Email"
                      placeholder="Email"
                      type="email"
                      value={email}
                      disabled={loading}
                      onChange={e => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row middle-xs m-bot-30 center-xs">
                  <ButtonGroup className="button-group--lg">
                    <Button disabled={loading} onClick={submit}>Login</Button>
                  </ButtonGroup>
                </div>
                <div className="row middle-xs m-bot-10 center-xs">
                  <div className="col-xs-4">
                    <p className="p--small">Version: {packageInfo.version}</p>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
