import React, { useState, useEffect, useContext } from "react";
import { CompanyContext } from "lib/context/CompanyContext";
import { InfiniteLoader, AutoSizer, Column, Table } from "react-virtualized";
import Button from "components/common/Button";
import ButtonGroup from "components/common/ButtonGroup";
import TextInput from "components/common/TextInput";
import Company from "lib/api/Company";
export default function CompanyList({ setShowModal }) {
  const { company, setCompany } = useContext(CompanyContext);

  const [data, setData] = useState([]);
  // const [data, setData] = useState([
  //   {
  //     name: "System",
  //     reference_id: "SYSTEM",
  //     client: {
  //       name: "ComputerEase",
  //       reference_id: "CE"
  //     }
  //   }
  // ]);
  const [total, setTotal] = useState(1);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  async function load() {
    await setLoading(true);
    let params = {
      offset: 0,
      limit: 50
    }
    if(search){
      params["search"] = search;
    }
    let results = await Company.list(params);
    await setTotal(results.total);
    await setData(results.data);
    await setLoading(false);
  }



  function isRowLoaded({ index }) {
    return !!data[index];
  }
  async function loadMoreRows({ startIndex, stopIndex }) {
    if (!loading) {
      await setLoading(true);
      let params = {
        offset: startIndex,
        limit: stopIndex - startIndex + 1
      }
      if(search){
        params["search"] = search;
      }

      let response = await Company.list(params);
      if (response.total != total) {
        await setTotal(response.total);
      }
      await setData([...data, ...response.data]);
      await setLoading(false);
    }
  }

  async function setSystemScope() {
    await setCompany({
      uuid: false,
      name: "System",
      reference_id: "SYSTEM",
      client: {
        name: "ComputerEase",
        reference_id: "CE"
      }
    });
    setShowModal(false);
  }


  useEffect(() => {
    load();
    return ()=>{setLoading(false)};
  }, []);
  return (
    <div>
      <div className="row">
        <div className="col-xs-6">
          <ButtonGroup>
            <Button className="button--icon" onClick={setSystemScope}>
              System Overview
            </Button>
          </ButtonGroup>
        </div>
        <div className="col-xs-6 text-right">
          <ButtonGroup>
            <div className="input-group">
              <TextInput
                className="m-bot-0"
                label={null}
                placeholder="Search..."
                value={search}
                disabled={false}
                onChange={e => setSearch(e.target.value)}
              />
              <Button className="button--icon" onClick={load}>
                Search
              </Button>
            </div>
          </ButtonGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <div style={{ height: "200px", width: "100%" }}>
            <div style={{ display: "flex", height: "100%" }}>
              <div style={{ flex: "1 1 auto" }}>
                <InfiniteLoader
                  isRowLoaded={isRowLoaded}
                  loadMoreRows={loadMoreRows}
                  rowCount={total}
                >
                  {({ onRowsRendered, registerChild }) => (
                    <AutoSizer>
                      {({ height, width }) => (
                        <Table
                          className="virtualized"
                          headerHeight={40}
                          width={width}
                          height={height}
                          rowCount={total}
                          rowHeight={40}
                          disableHeader={false}
                          rowGetter={({ index }) => data[index] || {}}
                          onRowsRendered={onRowsRendered}
                          ref={registerChild}
                        >
                          <Column
                            label="Company ID"
                            dataKey="code"
                            width={width / 3}
                            cellRenderer={({ cellData }) => {
                              if (!cellData) {
                                return "Loading...";
                              }
                              return cellData;
                            }}
                          />
                          <Column
                            label="Company Name"
                            dataKey="name"
                            width={width / 3}
                            cellRenderer={({ rowData }) => {
                              if (!rowData.name) {
                                return "Loading...";
                              }
                              return rowData.name;
                            }}
                          />
                          <Column
                            label=""
                            dataKey="uuid"
                            className="text-right"
                            headerClassName="text-right"
                            width={width / 3}
                            cellRenderer={({ rowData, cellData }) => {
                              if (cellData === undefined) {
                                return "Loading...";
                              }
                              return (
                                <div style={{ alignItems: "right" }}>
                                  <ButtonGroup>
                                    <Button
                                      className="m-bot-0"
                                      onClick={() => {
                                        setCompany(rowData);
                                        setShowModal(false);
                                      }}
                                    >
                                      Select
                                    </Button>
                                  </ButtonGroup>
                                </div>
                              );
                            }}
                          />
                        </Table>
                      )}
                    </AutoSizer>
                  )}
                </InfiniteLoader>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
