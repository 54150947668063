import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Client from "lib/api/Client";
import { InfiniteLoader, AutoSizer, Column, Table } from "react-virtualized";
import "react-virtualized/styles.css";
import PageLayout from "components/layouts/PageLayout";
import Button from "components/common/Button";
import ButtonGroup from "components/common/ButtonGroup";
import TextInput from "components/common/TextInput";
import { CompanyContext } from "lib/context/CompanyContext";
import { exportRecordsAsCSV } from "lib/utils/csv";

export default function ClientList(props) {
  const { company } = useContext(CompanyContext);
  const [records, setRecords] = useState([]);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const [recordsPerPage, setRecordsPerPage] = useState(100);

  async function load() {
    await setLoading(true);
    let params = {
      offset: 0,
      limit: recordsPerPage,
    }
    if(company.uuid && company.client.uuid !== undefined){
      params.uuid = company.client.uuid;
    }
    if(search){
      params.search = search;
    }
    let response = await Client.get(params);
    
    await setRecords(response.data);
    await setTotal(response.total);
    await setLoading(false);
  }
  function isRowLoaded({ index }) {
    return !!records[index];
  }
  async function loadMoreRows({ startIndex, stopIndex }) {
    if (loading) return;

    await setLoading(true);
    let params = {
      offset: startIndex,
      limit: Math.max(recordsPerPage, stopIndex - startIndex + 1)
    }
    if(search){
      params.search = search;
    }
    let response = await Client.get(params);
    if (response.total !== total) {
      await setTotal(response.total);
    }

    const newData = response.data;
    setRecords(prevRecords => [...prevRecords, ...newData]);
    await setLoading(false);
  }

  async function fetchAllRecords() {
    const allRecords = [];
    let hasMore = true;
    let offset = 0;
  
    while (hasMore) {
      let params = {
        offset: offset,
        limit: 1000
        // , fields: ["uuid", "name", "code", "type", "id", "desktop_version", "expiration", "created_at", "updated_at"]
      };
      
      if(company.uuid && company.client.uuid !== undefined){
        params.uuid = company.client.uuid;
      }
      if(search){
        params.search = search;
      }
  
      const response = await Client.get(params);
      allRecords.push(...response.data);
  
      hasMore = allRecords.length < response.total;
      offset += response.data.length;
    }
  
    return allRecords;
  }
  
  async function exportAllRecordsAsCSV() {
    setLoading(true); // Show loading indicator if necessary
    try {
      const allRecords = await fetchAllRecords();
      const columnsToSkip = ["permissions", "settings", "notes"];

      await exportRecordsAsCSV(allRecords, columnsToSkip, 'client-export.csv');
    } catch (error) {
      console.error('An error occurred while fetching records:', error);
      // Handle errors as appropriate for your application
    }
    setLoading(false); // Hide loading indicator
  }  

  useEffect(() => {
    load();
    return () => { setLoading(false) };
  }, [company]);

  const columns = 5;
  return (
    <PageLayout>
      <h1>Clients</h1>
      <div className="hr hr--darkest"></div>
      <div className="row">
        <div className="col-xs-6">
          {/* <ButtonGroup>
            <Link className="button" to="/client">
              Create
            </Link>
          </ButtonGroup> */}
        </div>
        <div className="col-xs-6 end-xs">
          <ButtonGroup>
            <div className="input-group">
            {loading && (
                <div className="loading-container">
                  <div className="loading-icon"></div>
                </div>
              )} 
              <TextInput
                className="m-bot-0"
                label={null}
                placeholder="Search..."
                value={search}
                disabled={false}
                onChange={e => setSearch(e.target.value)}
              />
              <Button className="button--icon" onClick={load}>
                Search
              </Button>
              <Button className="button--icon" onClick={exportAllRecordsAsCSV}>
                Export
              </Button>
               
            </div>
            {/* <CheckBox
                type="checkbox"
                checked={selectAll}
                onChange={toggleAll}
              /> */}
          </ButtonGroup>
          <div>{records.length} of {total} loaded</div>
        </div>
      </div>
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        rowCount={total}
      >
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer>
            {({ height, width }) => {
              return (
                <Table
                  headerHeight={40}
                  width={width}
                  height={height-120}
                  rowCount={total}
                  rowHeight={40}
                  disableHeader={false}
                  rowGetter={({ index }) => records[index] || {}}
                  onRowsRendered={onRowsRendered}
                  ref={registerChild}
                >
                  <Column
                    label="Name"
                    dataKey="name"
                    width={width / columns}
                    cellRenderer={({ rowData }) => {
                      if (!rowData.uuid) {
                        return "Loading...";
                      }
                      return (rowData.name);
                    }}
                  />
                  <Column
                    label="Code"
                    dataKey="code"
                    width={width / columns}
                    cellRenderer={({ rowData, cellData }) => {
                      if (!cellData) {
                        return "Loading....";
                      }
                      return cellData;
                    }}
                  />
                  <Column
                    label="Status"
                    dataKey="enabled"
                    width={width / columns}
                    cellRenderer={({ rowData }) => {
                      if (!rowData.enabled) {
                        return "Loading...";
                      }
                      return rowData.enabled ? "Enabled" : "Disabled";
                    }}
                  />
                  <Column
                    label="Created"
                    dataKey="created_at"
                    width={width / columns}
                    cellRenderer={({ rowData, cellData }) => {
                      if (!rowData.enabled) {
                        return "Loading...";
                      }
                      return new Date(cellData).toLocaleString() 
                    }}
                  />
                  <Column
                    label=""
                    dataKey="name"
                    width={width / 6}
                    cellRenderer={({ rowData }) => {
                      if (!rowData.uuid) {
                        return "Loading...";
                      }
                      return (
                        <div style={{ alignItems: "right" }}>
                          <ButtonGroup>
                            <Link className="button m-bot-0" to={`/client/${rowData.uuid}/`}>
                              View
                            </Link>
                          </ButtonGroup>
                        </div>
                      );
                    }}
                  />
                </Table>
              );
            }}
          </AutoSizer>
        )}
      </InfiniteLoader>
    </PageLayout>
  );
}