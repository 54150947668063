import React, { useReducer, useEffect } from 'react';

const initialState = {
  uuid: '',
  first_name: '',
  last_name: '',
  email: ''
};

const userReducer = (state, newState) => {
  if (newState === null) {
    sessionStorage.removeItem('user');
    return initialState;
  }
  return { ...state, ...newState };
};

export const UserContext = React.createContext();

export function UserProvider(props) {
  // Use reducer for user state management
  const [user, setUser] = useReducer(
    userReducer,
    JSON.parse(sessionStorage.getItem('user')) || initialState
  );

  // Update session storage when user state changes
  useEffect(() => {
    sessionStorage.setItem('user', JSON.stringify(user));
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {props.children}
    </UserContext.Provider>
  );
}
