import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Role from "lib/api/Role";
import { InfiniteLoader, AutoSizer, Column, Table } from "react-virtualized";
import "react-virtualized/styles.css";
import PageLayout from "components/layouts/PageLayout";
import Button from "components/common/Button";
import ButtonGroup from "components/common/ButtonGroup";
import TextInput from "components/common/TextInput";
import { CompanyContext } from "lib/context/CompanyContext";

export default function RoleList(props) {
  const { company } = useContext(CompanyContext);

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(1);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const hasNextPage = data.length < total;

  const [recordsPerPage, setRecordsPerPage] = useState(100);

  async function loadNextPage({ startIndex, stopIndex }) {
    if (loading) return;
    let params = {};
    if(company.uuid || search){
      if(company.uuid){
        params["company_uuid"] = company.uuid;
      }
      if(search){
        params["search"] = search;
      }
    }else{
      params = false;
    }
    await setLoading(true);
    let response = await Role.list(startIndex, Math.max(recordsPerPage, stopIndex - startIndex + 1), params);
    if (response.total !== total) {
      await setTotal(response.total);
    }
    const newData = response.data;
    setData(prevRecords => [...prevRecords, ...newData]);
    await setLoading(false);
  }

  async function load() {
    let params = {};
    if(company.uuid || search){
      if(company.uuid){
        params["company_uuid"] = company.uuid;
      }
      if(search){
        params["search"] = search;
      }
    }else{
      params = false;
    }
    await setLoading(true);
    let results = await Role.list(0, recordsPerPage, params);
    
    await setTotal(results.total);
    await setData(results.data);
    await setLoading(false);
  }

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreRows = loading ? () => {} : loadNextPage;

  // Every row is loaded except for our loading indicator row.
  const isRowLoaded = ({ index }) => !hasNextPage || index < data.length;

  useEffect(() => {
    load();
    return ()=>{setLoading(false)};
  }, [company]);
  return (
    <PageLayout>
    <h1>Roles</h1>
    <div className="hr hr--darkest"></div>
    <div className="row">
      <div className="col-xs-6">
        {/* <ButtonGroup>
          <Link className="button" to="/group">
            Create
          </Link>
        </ButtonGroup> */}
      </div>
      <div className="col-xs-6 end-xs">
        <ButtonGroup>
          <div className="input-group">
            <TextInput
              className="m-bot-0"
              label={null}
              placeholder="Search..."
              value={search}
              disabled={false}
              onChange={e => setSearch(e.target.value)}
            />
            <Button className="button--icon" onClick={load}>
              Search
            </Button>
          </div>
        </ButtonGroup>
        <div>{data.length} of {total} loaded</div>
      </div>
    </div>
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        rowCount={total}
      >
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer>
            {({ height, width }) => {
              return (
                <Table
                  headerHeight={40}
                  width={width}
                  height={height-120}
                  rowCount={total}
                  rowHeight={40}
                  disableHeader={false}
                  rowGetter={({ index }) => data[index] || {}}
                  onRowsRendered={onRowsRendered}
                  ref={registerChild}
                >
                  <Column
                    label="Name"
                    dataKey="name"
                    width={width / 4}
                    cellRenderer={({ rowData, cellData }) => {
                      if (cellData === undefined) {
                        return "Loading...";
                      }
                      return cellData;
                    }}
                  />
                  <Column
                    label="Company"
                    dataKey="company"
                    width={width / 4}  
                    cellRenderer={({ rowData, cellData }) => {
                      if (cellData === undefined) {
                        return "Loading...";
                      }
                      // console.log(cellData);
                      return cellData.name ? `${cellData.name}` : "";
                    }}
                  />
                  <Column
                    label="Created"
                    dataKey="created_at"
                    width={width / 4}
                    cellRenderer={({ rowData, cellData }) => {
                      if (cellData === undefined) {
                        return "Loading...";
                      }
                      return new Date(cellData).toLocaleString() 
                    }}
                  />
                  <Column
                    label=""
                    dataKey="uuid"
                    width={width / 4}
                    cellRenderer={({ rowData }) => {
                      if (!rowData.uuid) {
                        return "";
                      }
                      return (
                        <ButtonGroup>
                        <Link className='button' to={`/role/${rowData.uuid}/`}>
                          View
                        </Link>
                        </ButtonGroup>
                      );
                    }}
                  />
                </Table>
              );
            }}
          </AutoSizer>
        )}
      </InfiniteLoader>
    </PageLayout>
  );
}
