import React from "react";
import "./style.css";

import AppHeader from "components/common/AppHeader";
import LeftNav from "components/common/LeftNav";

export default function PageLayout({ children }) {
  return (
    <div className="app">
      <AppHeader />
      <div className="app-body">
        <LeftNav />
        <div className="app-body__content">
          <div className="app-body__page">{children}</div>
        </div>
      </div>
    </div>
  );
}
