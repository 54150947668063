import React, { useState, useEffect, useContext } from "react";
import { FlashMessageContext } from "lib/context/FlashMessageContext";
import { v4 } from "uuid";
import PageLayout from "components/layouts/PageLayout";
import Client from "lib/api/Client";
import Notification from "lib/api/Notification";
import {
  Button,
  TextArea,
  TextInput,
  Checkbox,
  CheckboxGroup,
  Dropdown
} from "components/common";
export default function NotificationForm(props) {
  const [uuid, setUuid] = useState(false);
  const [type, setType] = useState("system");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [clients, setClients] = useState("");
  const [clientMap, setClientMap] = useState({});
  const [notifications, setNotifications] = useState([]);
  const [notification, setNotification] = useState({target_uuid:"",message:""});
  const [start_date, setStartDate] = useState(null);
  const [end_date, setEndDate] = useState(null);
  const { flash_message, setFlashMessage } = useContext(FlashMessageContext);
  async function save() {
    console.log(notifications);
    // if (props.match.params.uuid) {
    //   await Notification.update(props.match.params.uuid, {
    //     message: message,
    //     clients: clients
    //   });
    // } else {
    let payload = notifications.map((record)=>{
      return {
        ...record,
        type: type,
        title: title,
        message: message,
        start_at: start_date,
        end_at: end_date
      }
    })
    console.log(payload);
    let response =  await Notification.create(payload);
    // }
    console.log(response);
    // await flash_message("Notifications created.");
    props.history.push(`/notifications`);
  }
  async function updateClients(){
    let targets = clients.replace(/\n/g, ',').split(',');
    let {data} = await Client.get({
      uuid: targets,
      offset: 0,
      limit: 1000
    });
    let newNotifications = data.map((target)=>{
        return {
          target_uuid: target.uuid,
          type: type,
          message: message
        }
    });
    newNotifications = [...new Set(newNotifications)];
    await setClientMap(data.reduce((prev,client) => ({...prev, [client.uuid]: client}), clientMap));
    await setNotifications(newNotifications);
    await setClients("");
    // await setNotifications(Object.values(clientMap));
  }
  async function load(params) {
    console.log(params);
    for (let [k, v] of params) {
      console.log(k);
      console.log(v);
    }
    // console.log(JSON.stringify(params));
    // console.log(props.location.search);
    // (new URL(document.location)).searchParams;
    // const notifications = await Notification.get(params);
    // await setNotifications(notifications);
  }
  async function remove() {
    await Notification.delete(uuid);
    props.history.push(`/notifications`);
  }
  console.log(props.location.search);
  useEffect(() => {
    if (props.location.search) {
      let params = new URLSearchParams(props.location.search);
      if(params){
        load(params);
      }
    }
  }, []);
  const deleteBtn = props.match.params.uuid ? (
    <Button className="button button--danger" onClick={remove}>
      Delete
    </Button>
  ) : (
    ""
  );
  async function addNotification() {
    await setNotifications([...notifications, {uuid: v4(), message: notification}]);
    // await setNewNotifications([...newNotifications, {message: newNotification}]);
    await setNotification("");
  }
  async function deleteNotification(target_uuid) {
    console.log(`removing ${target_uuid}`);
    await setNotifications(notifications.filter((n)=>{return n.target_uuid !== target_uuid}));
  }
  const ClientList = notifications.map((record)=>{
    let client = clientMap[record.target_uuid];
    
    return (
      <div className="row"  key={record.target_uuid}>
      <div className="col-xs-12 col-sm-6">
      {client.name} ({client.code})<br/>
        {record.target_uuid}
      </div>
      <div className="col-xs-12 col-sm-6">
      <Button className="button button--danger" onClick={(e)=>{deleteNotification(record.target_uuid)}}>Remove</Button>
    </div>
    </div>
      )
  })
  return (
    <PageLayout>
      <h1>Notification Details</h1>
      <div className="hr hr--darkest"></div>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <TextInput
            className="m-bot-0"
            label="Title"
            placeholder="Enter Title"
            type="text"
            value={title}
            disabled={false}
            onChange={e => setTitle(e.target.value)}
          />
        </div>
        <div className="col-xs-12 col-sm-6">
          <Dropdown
            className="form-select m-bot-0"
            label="Type"
            value={type}
            onChange={e => setType(e.target.value)}
            disabled={false}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <TextArea
            label="Message"
            placeholder="Enter Message"
            value={message}
            disabled={false}
            onChange={e => setMessage(e.target.value)}
          />
        </div>
      </div>
      {/* <div className="row">
        <div className="col-xs-12">
          <TextArea
            label="Message"
            placeholder="Details"
            value={details}
            disabled={false}
            onChange={e => setDetails(e.target.value)}
          />
        </div>
      </div> */}
      <div className="row">
      <div className="col-xs-12 col-sm-6">
        <label>
          <span className="label__span">Start</span>
          <input type="datetime-local"
            value={start_date || ""}
            onChange={e => setStartDate(e.target.value)}
          />
        </label>
        </div>
        <div className="col-xs-12 col-sm-6">
        <label>
          <span className="label__span">End</span>
          <input type="datetime-local"
            value={end_date || ""}
            onChange={e => setEndDate(e.target.value)}
          />
        </label>
        </div>
        </div>
        <span className="label__span">Clients</span>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
        <TextArea
                    label=""
                    className="m-bot-0"
                    value={clients}
                    placeholder="One Client per Line..."
                    onChange={e => setClients(e.target.value)}
                  />
        </div>
        <div className="col-xs-12 col-sm-6">
        <Button className="button button--primary" onClick={updateClients} >Add</Button>
         
        </div>
      </div>
      {ClientList}
      <div className="hr"></div>
      {/* <div className="row">
        <div className="col-xs-12">
          <TextArea
            label="Clients"
            value={clients}
            placeholder="Enter Clients"
            onChange={e => setClients(e.target.value)}
          />
          <Button className="button" onClick={updateClients}>
            Generate
          </Button>
        </div>
      </div> */}
      <div className="hr hr--darkest m-top-10 m-bot-20"></div>
      <div className="row">
        <div className="col-xs-6">
          <div className="button-group">
            <Button className="button" onClick={save}>
              Save
            </Button>
            <a className="button button--plain" href="#" onClick={props.history.goBack}>
              Cancel
            </a>
          </div>
        </div>
        <div className="col-xs-6 end-xs">
          <div className="button-group">
            {deleteBtn}
          </div>
        </div>
      </div>
    </PageLayout>
  );
}
