function convertToCSV(arr, columnsToSkip) {
  if (!arr.length) return '';

  const keys = Object.keys(arr[0]).filter(key => !columnsToSkip.includes(key));
  const csvRows = arr.map(row => keys.map(key => {
    let cell = row[key] === null || row[key] === undefined ? '' : row[key];
    // Check if the cell is an object/array and convert to a JSON string if so
    if (typeof cell === 'object') {
      cell = JSON.stringify(cell).replace(/"/g, '""'); // Convert object to string and escape double quotes
    } else {
      cell = cell.toString().replace(/"/g, '""'); // Escape double quotes for normal strings
    }
    if (cell.search(/("|,|\n)/g) >= 0) cell = `"${cell}"`; // Quote cells with commas/newlines
    return cell;
  }).join(','));

  return [keys.join(',')].concat(csvRows).join('\n');
}

export async function exportRecordsAsCSV(allRecords, columnsToSkip, filename = 'exported-data.csv') {
  try {
    const csvString = convertToCSV(allRecords, columnsToSkip);
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  } catch (error) {
    console.error('An error occurred while exporting records:', error);
  }
}
