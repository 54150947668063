import React from "react";
import "./style.css";

export default function LeftNavLink({ linkLabel, linkClass }) {
  return (
    <span className={`left-nav__link ${linkClass}`}>
      <span className="flex__parent">
        <span className="flex__child">
          <span className="text-elipsis">{linkLabel}</span>
        </span>
        {/*
        <span className="flex__child flex__child--notification">
          <span className="status-tag status-tag--sm status-tag--attention">4</span>
        </span>
        */}
      </span>
    </span>
  );
}
