import React from 'react';
import './style.css'; // Assuming you have similar styles for Dropdown like TextInput
import LabelSpan from '../LabelSpan';

export default function Dropdown({
    label,
    className,
    value,
    onChange,
    disabled
}) {
    const selectLabel = label ? <LabelSpan>{label}</LabelSpan> : null;

    return (
        <label>
            {selectLabel}
            <div className="select-wrapper">
                <select
                    className={`${className}`}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                >
                    <option value="upcoming">Upcoming</option>
                    <option value="learning">Learning</option>
                    <option value="release">Release</option>
                    <option value="system">System</option>
                    <option value="unknown">Unknown</option>
                    <option value="marketing">Marketing</option>
                    <option value="billing">Billing</option>
                </select>
            </div>
        </label>
    );
}
