import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import User from "lib/api/User";
import {
  TextArea,
  TextInput,
  Checkbox
} from "components/common";

import PageLayout from "components/layouts/PageLayout";
export default function UserForm(props) {
  const [uuid, setUuid] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");
  const [mobile_phone, setMobilePhone] = useState("");
  const [confirmation_token, setConfirmationToken] = useState("");
  const [sent_at, setSentAt] = useState("");
  const [verified_at, setVerifiedAt] = useState("");
  const [verified, setVeririfed] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [created_at, setCreatedAt] = useState("");
  const [updated_at, setUpdatedAt] = useState("");
  const [groups, setGroups] = useState([]);
  const [role, setRole] = useState({name:"",expenses:false, timesheets:false, settings:{}, permissions:{}});
  const [company, setCompany] = useState({name:"",code:""});
  async function load(uuid) {
    const user = await User.load(uuid);
    console.log(user);
    await setUuid(user.uuid);
    await setFirstName(user.first_name);
    await setLastName(user.last_name);
    await setCode(user.code);
    await setEmail(user.email);
    await setMobilePhone(user.mobile_phone || "");
    await setConfirmationToken(user.confirmation_token || "");
    await setSentAt(user.setSentA || "");
    await setVerifiedAt(user.verified_at);
    await setVeririfed(user.verified);
    await setEnabled(user.enabled);
    await setCreatedAt(user.created_at);
    await setUpdatedAt(user.updated_at);
    await setGroups(user.groups);
    await setRole(user.role || {name:"",expenses:false, timesheets:false, settings:{}, permissions:{}});
    await setCompany(user.company);
  }
  async function remove() {
    await User.remove(uuid);
    props.history.push(`/users`);
  }
  useEffect(() => {
    if (props.match.params.uuid) {
      load(props.match.params.uuid);
    }
  }, []);
  const deleteBtn = props.match.params.id ? (
    <button variant="danger" onClick={remove}>
      delete
    </button>
  ) : (
    ""
  );
  const GroupList = groups.map((group) => {
    return (
      <div className="row" key={group.uuid}>
        <div className="col-xs-12 col-sm-6">
          <Link to={`/group/${group.uuid}/`}>{group.name}</Link>
        </div>
        <div className="col-xs-12 col-sm-6">
        <TextInput
            type="text"
            value={group.access_codes}
            disabled={true}
          />
        </div>
      </div>
    )
  })
  return (
    <PageLayout>
      <h1>User Details</h1>
      <div className="hr hr--darkest"></div>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="UUID"
            placeholder="Uuid"
            type="text"
            value={uuid}
            disabled={true}
            readOnly
            onChange={e => setUuid(e.target.value)}
          />
        </div>
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="Code"
            placeholder="Code"
            type="text"
            value={code}
            disabled={true}
            readOnly
            onChange={e => setCode(e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="First Name"
            placeholder="First Name"
            type="text"
            value={first_name}
            disabled={true}
            onChange={e => setFirstName(e.target.value)}
          />
        </div>
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="Last Name"
            placeholder="Last Name"
            type="text"
            value={last_name}
            disabled={true}
            onChange={e => setLastName(e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="Email"
            placeholder="Email"
            type="text"
            value={email}
            disabled={true}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="Mobile Phone"
            value={mobile_phone}
            disabled={true}
            onChange={e => setMobilePhone(e.target.value)}
          />
        </div>
      </div>
      <div className="hr"></div>
      <h2>Company</h2>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="Name"
            value={company.name}
            disabled={true}
            onChange={e => setCompany(Object.assign(company, {name:e.target.value}))}
          />
        </div>
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="Code"
            value={company.code}
            disabled={true}
            onChange={e => setCompany(Object.assign(company, {code:e.target.value}))}
          />
        </div>
      </div>
      <div className="hr hr--darkest"></div>
      <h2>Groups</h2>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <label><span className="label__span">Name</span></label>
        </div>
        <div className="col-xs-12 col-sm-6">
          <label><span className="label__span">Access Codes</span></label>
        </div>
      </div>
      {GroupList}
      <div className="hr hr--darkest"></div>
      <h2>Role</h2>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <Link to={`/role/${role.uuid}/`}>{role.name}</Link>
        </div>
        <div className="col-xs-12 col-sm-6">
          <Checkbox
            title="Expenses"
            checked= {role.expenses}
            disabled={true}
            onChange={e => setRole(Object.assign(role, {expenses: e.target.value}))}
          />
          <Checkbox
            title="Timesheets"
            checked= {role.timesheets}
            disabled={true}
            onChange={e => setRole(Object.assign(role, {timesheets: e.target.value}))}
          />
        </div>
        
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
        <TextArea
            label="Permissions"
            value={JSON.stringify(role.permissions, null, 2) || ""}
            disabled={true}
            onChange={e => setRole(Object.assign(role, {permissions: e.target.value}))}
          />
        </div>
        <div className="col-xs-12 col-sm-6">
          <TextArea
            label="Settings"
            value={JSON.stringify(role.settings, null, 2) || ""}
            disabled={true}
            onChange={e => setRole(Object.assign(role, {settings: e.target.value}))}
          />
        </div>
      </div>
      <div className="hr hr--darkest m-top-20 m-bot-20"></div>
      <div className="hr"></div>
      <h2>API Information</h2>
      {/* <div className="row">
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="Confirmation Token"
            value={confirmation_token}
            disabled={true}
            onChange={e => setConfirmationToken(e.target.value)}
          />
        </div>
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="Invited at"
            value={sent_at}
            disabled={true}
            onChange={e => setSentAt(e.target.value)}
          />
        </div>
      </div> */}
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="Created At"
            value={created_at}
            disabled={true}
            onChange={e => setCreatedAt(e.target.value)}
          />
        </div>
        <div className="col-xs-12 col-sm-6">
          <TextInput
            label="Last Updated"
            value={updated_at}
            disabled={true}
            onChange={e => setUpdatedAt(e.target.value)}
          />
        </div>
      </div>
      <div className="row m-bot-100">
        <div className="col-xs-6">
          <div className="button-group">
            {/* <Button className="button" onClick={save}>
                Save
              </Button> */}
            <a className="button button--plain" href="#" onClick={props.history.goBack}>
              Cancel
            </a>
          </div>
        </div>
        <div className="col-xs-6 end-xs">
          <div className="button-group">
            {deleteBtn}
          </div>
        </div>
      </div>
    </PageLayout>
  );
}
